import './ModalBox.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useEffect, useState } from 'react';
import { useGlobalMutation, useGlobalState } from '../../utils/container';
import CONST_STRINGS from '../../constants/texts';
import { useNavigate } from 'react-router-dom';
import { getTokens } from '../../utils/api/requests';


const { MODAL_TYPES, ROLES,ALERT_TYPES } = CONST_STRINGS;


const ModalBox = ({ makeVisible, menuType }) => {
    const [show, setShow] = useState(makeVisible);
    useEffect(() => {
        setShow(makeVisible);
    }, [makeVisible]);

    const navigate = useNavigate();
    const stateCtx = useGlobalState()
    const mutationCtx = useGlobalMutation()

    const handleModalMenuClose = (clicked) => {
        console.log('handleModalMenuClose');
        setShow(false);
        mutationCtx.toggleModalMenu(!stateCtx.modalMenu)
    }

    const handleAcceptInvited = async (params) => {
        await stateCtx.rtcClient.setClientRole('host');
        let audioTrack = await stateCtx.rtcClient.startLive(stateCtx.config.microphoneId)
        mutationCtx.setLocalStream(audioTrack);
        mutationCtx.toggleModalMenu(!stateCtx.modalMenu)
    }

    const handleLeaveCall = async (params) => {
        mutationCtx.clearAllStream();
        const whichRole = stateCtx.config.role;
        let url = '';
        switch (whichRole) {
            case ROLES.HOST.name:
            case ROLES.CO_HOST.name:
                url = '/organizers'
                break;
            case ROLES.AUDIENCE.name:
                url = '/audience'
                break;
            default:
                break;
        }
        mutationCtx.leaveCall();
        navigate(`${url}`, { replace: true });
    }

    const getMyRolePostfix = () => {
        switch (stateCtx.config.role) {
            case "Host":
                return ROLES.HOST.postfix;
            case "Co-Host":
                return ROLES.CO_HOST.postfix;
            case "Audience":
                return ROLES.AUDIENCE.postfix;
            default:
                break;
        }
    }

    const handleBreakoutJoin = async (breakoutChannelName) => {

        mutationCtx.clearAllStream();
        mutationCtx.setAlertBoxType(ALERT_TYPES.JOINING_BREAKOUT_ROOM.NAME);
        mutationCtx.toggleAlertBox(!stateCtx.alertBox);
        
        let my_postfix = getMyRolePostfix();
        const tokens = await getTokens({
            channelName: breakoutChannelName,
            uid: `${stateCtx.config.uid}${my_postfix}`
        })
        mutationCtx.updateConfig({
            rtcToken: tokens.tokenRTC, rtmToken: tokens.tokenRTM, uid: `${stateCtx.config.uid}${my_postfix}`, channelName: breakoutChannelName
        })
        mutationCtx.leaveCallJoinBr();
        setTimeout(() => {
            // alert()
            navigate(`/breakout`, { replace: true });
        }, 1500);
    }


    // let menuIcon = '';
    let menuTitle = '';
    let menuBody = '';
    let totalBody = '';
    switch (menuType) {
        case MODAL_TYPES.INVITED.NAME:
            //   menuIcon = MENU_TYPES.MENU.ICON;
            menuTitle = MODAL_TYPES.INVITED.PLACEHOLDER_TEXT;
            menuBody = <></>
            totalBody = <>
                <Modal
                    show={show}
                    onHide={() => handleModalMenuClose()}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeVariant='white' className='modalbox-header'>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {menuTitle}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='modalbox-body'>
                        <p>
                            You have been invited to Speak
                        </p>
                    </Modal.Body>
                    <Modal.Footer className='modalbox-footer'>
                        <Button variant="success" className='modalbox-buttons' onClick={() => { handleAcceptInvited() }} >
                            Accept
                        </Button>

                        <Button variant="danger" className='modalbox-buttons' onClick={() => { handleModalMenuClose() }}>
                            Decline
                        </Button>
                    </Modal.Footer>
                </Modal>

            </>
            break;
        case MODAL_TYPES.SURE_ENDCALL.NAME:
            //   menuIcon = MENU_TYPES.MENU.ICON;
            menuTitle = MODAL_TYPES.SURE_ENDCALL.PLACEHOLDER_TEXT;
            menuBody = <></>
            totalBody = <>
                <Modal
                    show={show}
                    onHide={() => handleModalMenuClose()}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeVariant='white' className='modalbox-header'>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {menuTitle}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Footer className='modalbox-footer'>
                        <Button variant="success" className='modalbox-buttons' onClick={() => { handleLeaveCall() }} >
                            Accept
                        </Button>

                        <Button variant="danger" className='modalbox-buttons' onClick={() => { handleModalMenuClose() }}>
                            Decline
                        </Button>
                    </Modal.Footer>
                </Modal>

            </>
            break;
        case MODAL_TYPES.SURE_JOIN_BREAKOUT.NAME:
            menuTitle = MODAL_TYPES.SURE_JOIN_BREAKOUT.PLACEHOLDER_TEXT;
            menuBody = <></>
            totalBody = <>
                <Modal
                    show={show}
                    onHide={() => handleModalMenuClose()}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeVariant='white' className='modalbox-header'>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {menuTitle}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Footer className='modalbox-footer'>
                        <Button variant="success" className='modalbox-buttons' onClick={() => { handleBreakoutJoin(MODAL_TYPES.SURE_JOIN_BREAKOUT.WHICH_BREAKOUT) }} >
                            Accept
                        </Button>

                        <Button variant="danger" className='modalbox-buttons' onClick={() => { handleModalMenuClose() }}>
                            Decline
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
            break;
        default:
            break;
    }
    return (
        totalBody
    )
}

export default ModalBox;
