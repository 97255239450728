import { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./BreakoutRoom.css";
import RTCClient from "../../utils/rtc-client";
import { RTMWrapper } from "../../utils/rtm-client";
import HeadingText from "../../components/HeadingText/HeadingText";
import Icon from "../../components/Icon/Icon";
import BodyText from "../../components/BodyText/BodyText";
import TopHeader from "../../GroupComponents/TopHeader/TopHeader";
import BottomNav from "../../GroupComponents/BottomNav/BottomNav";
import OffCanvasMenu from "../../components/OffCanvasMenu/OffCanvasMenu";
import AlertBox from "../../components/AlertBox/AlertBox";
import ModalBox from "../../components/ModalBox/ModalBox";
import Participant from "../../components/Participant/Participant";
import Slider from "react-slick";

import { useGlobalState, useGlobalMutation } from "../../utils/container";

import CONST_STRINGS from "../../constants/texts";
import { useNavigate } from "react-router-dom";

const { ICONS, ROLES, MENU_TYPES, MODAL_TYPES, ALERT_TYPES } = CONST_STRINGS;

function App() {
  // const params = new URLSearchParams(window.location.search)

  const stateCtx = useGlobalState();
  const mutationCtx = useGlobalMutation();
  const navigate = useNavigate();
  let localClient = null;
  let RTMHandler = RTMWrapper();
  const [muteAudio, setMuteAudio] = useState(true);
  const [AudioTrack, setAudioTrack] = useState(null);
  const [remoteUsers, setRemoteUsers] = useState({});
  const [remoteAudience, setremoteAudience] = useState({});

  useEffect(() => {
    console.log("remoteUsers", remoteUsers);
    if (localClient === null) {
      initRTC();
      RTMHandler.initRTM((previousMembers) => {
        previousMembers.forEach((memberUid) => {
          stateCtx.config.uid !== memberUid &&
            addRemoteUser({ uid: memberUid });
        });
      });
      RTMHandler.onEvents(rtmEventsObject);
      mutationCtx.setRTMClient(RTMHandler);
      // RTMHandler.getMembers();
    }
  }, []);

  const rtmEventMessageFromPeer = (messagePayload, peerId) => {
    // alert(messagePayload)
    console.log(messagePayload);

    let message = JSON.parse(messagePayload.text);
    switch (message.type) {
      case "action":
        // alert(message)
        if (message.action === "invited") {
          mutationCtx.setModalMenuType(MODAL_TYPES.INVITED.NAME);
          mutationCtx.toggleModalMenu(!stateCtx.modalMenu);
        } else if (message.action === "kicked") {
          mutationCtx.setModalMenuType(MODAL_TYPES.INVITED.NAME);
          mutationCtx.toggleModalMenu(!stateCtx.modalMenu);
          leaveCall();
        }
        // else if (message.action === 'requestinvite') {

        // }
        break;
      case "chat":
        break;

      default:
        break;
    }

    console.log("rtmeve rtmEventMessageFromPeer", message, peerId);
  };

  const leaveCall = async (params) => {
    mutationCtx.clearAllStream();
    const whichRole = stateCtx.config.role;
    let url = "";
    switch (whichRole) {
      case ROLES.HOST.name:
      case ROLES.CO_HOST.name:
        url = "/organizers";
        break;
      case ROLES.AUDIENCE.name:
        url = "/audience";
        break;
      default:
        break;
    }
    mutationCtx.leaveCall();
    navigate(`${url}`, { replace: true });
  };

  const rtmEventConnectionStateChanged = (state, reason) => {
    console.log("rtmeve rtmEventConnectionStateChanged", state, reason);
  };
  const rtmEventChannelMessage = async (recv_message, memberId) => {
    if (memberId === stateCtx.config.uid) return;
    console.log("rtmeve rtmEventChannelMessage", recv_message, memberId);
    console.log(recv_message);
    let message = JSON.parse(recv_message.text);
    switch (message.type) {
      case "action":
        if (message.action === "requestinvite") {
          ALERT_TYPES.INVITATION_REQUESTED.WHICH_USER = memberId;
          mutationCtx.setAlertBoxType(ALERT_TYPES.INVITATION_REQUESTED.NAME);
          mutationCtx.toggleAlertBox(!stateCtx.alertBox);
          setTimeout(() => {
            mutationCtx.toggleAlertBox(false);
          }, 2500);
        }
        break;
      default:
        break;
    }
  };
  const rtmEventMemberJoined = (memberId) => {
    addRemoteUser({ uid: memberId });
    console.log("rtmeve rtmEventMemberJoined", memberId);
  };
  const rtmEventMemberLeft = (memberId) => {
    console.log("rtmeve rtmEventMemberLeft", memberId);
    // console.debug(`onUserLeft ${remoteUser.uid} reason: ${reason}`)
    removeRemoteUser({ uid: memberId });
  };

  const rtmEventsObject = {
    MessageFromPeer: rtmEventMessageFromPeer,
    ConnectionStateChanged: rtmEventConnectionStateChanged,
    ChannelMessage: rtmEventChannelMessage,
    MemberJoined: rtmEventMemberJoined,
    MemberLeft: rtmEventMemberLeft,
  };

  const onUserJoined = (remoteUser) => {
    console.debug(`onUserJoined ${remoteUser.uid}`);
    addRemoteUser(remoteUser);
  };

  const onUserLeft = (remoteUser, reason) => {
    console.debug(`onUserLeft ${remoteUser.uid} reason: ${reason}`);
    removeRemoteUser(remoteUser);
  };

  const onUserPublished = (remoteUser, mediaType) => {
    console.debug(`onUserPublished ${remoteUser.uid}, mediaType= ${mediaType}`);
    localClient
      .subscribe(remoteUser, mediaType)
      .then((mRemoteTrack) => {
        // addRemoteUser(remoteUser)
        if (mediaType === "audio") {
          console.log("subscribe audio success");
          mRemoteTrack.play();
        }
      })
      .catch((err) => {
        <AlertBox
          alertMessage={`stream ${remoteUser.getId()} subscribe failed: ${err}`}
        />;
      });
  };

  const onUserUnPublished = (remoteUser, mediaType) => {
    // remoteUser:
    // mediaType: "audio" | "video" | "all"
    console.debug(`onUserUnPublished ${remoteUser.uid}`);
    // removeRemoteUser(remoteUser)
    // if (mediaType === 'video' || mediaType === 'all') {

    // }

    // if (mediaType === 'audio' || mediaType === 'all') {

    // }
  };

  const onNetworkQualityChange = (stats) => {
    const { uplinkNetworkQuality, downlinkNetworkQuality } = stats;
    let signal = Math.max(uplinkNetworkQuality, downlinkNetworkQuality);
    let style = stateCtx.networkQuality.style;
    let placeholder = stateCtx.networkQuality.placeholder;
    // if (signal < 1) {
    //   style = "white";
    // } else
    if (signal >= 1 && signal <= 2) {
      style = "text-success"; // good
      placeholder = ICONS.NETWORK.PLACEHOLDER_TEXT.GOOD;
    } else if (signal >= 3 && signal <= 4) {
      style = "text-warning"; // normal
      placeholder = ICONS.NETWORK.PLACEHOLDER_TEXT.NORMAL;
    } else if (signal >= 5 && signal <= 6) {
      style = "text-danger"; // bad
      placeholder = ICONS.NETWORK.PLACEHOLDER_TEXT.BAD;
    }
    // console.log('Change in Network: ', uplinkNetworkQuality, downlinkNetworkQuality);
    mutationCtx.setNetworkStatus({ style, placeholder });
  };

  const handleDominantUser = (volumes) => {
    // console.log('dominant who', volumes);
    if (volumes.length > 0) {
      let userId = volumes[0].uid;
      let maxLevel = volumes[0].level;
      volumes.forEach((volume, index) => {
        if (maxLevel < volume.level) {
          userId = volume.uid;
          maxLevel = volume.level;
        }
      });
      mutationCtx.setDominantUser(userId);
    }
  };

  const connectionStateChanged = (newState) => {
    console.log("connectionStateChanged: ", newState);
  };

  const addRemoteUser = (remoteUser) => {
    const remoteUid = remoteUser.uid;
    remoteUsers[remoteUid] = remoteUser;
    setRemoteUsers({ ...remoteUsers });
  };

  const removeRemoteUser = (remoteUser) => {
    delete remoteUsers[remoteUser.uid];
    setRemoteUsers(remoteUsers);
  };

  const toggleAudio = () => {
    const newValue = !muteAudio;
    if (newValue) {
      localClient._client.unpublish(AudioTrack);
    } else {
      localClient._client.publish(AudioTrack);
    }
    setMuteAudio(newValue);
  };

  const doLeave = () => {
    localClient.leave().then(() => {
      localClient.stopLive();
      localClient.destroy();
      // routerCtx.history.push('/')
    });
  };

  const createLocalClient = () => {
    const client = new RTCClient();
    let agoraClient = client.createClient();
    client.on("connection-state-change", connectionStateChanged);
    client.on("user-published", onUserPublished);
    client.on("user-unpublished", onUserUnPublished);
    client.on("network-quality", onNetworkQualityChange);
    client.on("user-joined", onUserJoined);
    client.on("user-left", onUserLeft);
    agoraClient.enableAudioVolumeIndicator();
    client.on("volume-indicator", handleDominantUser);

    return client;
  };

  const initRTC = async () => {
    console.log("initRTC");
    let client = createLocalClient();
    localClient = client;
    mutationCtx.setRTCClient(localClient);
    try {
      await client.join(
        stateCtx.config.channelName,
        stateCtx.config.rtcToken,
        stateCtx.config.uid
      );
      (stateCtx.config.role === ROLES.HOST.name ||
        stateCtx.config.role === ROLES.CO_HOST.name) &&
        (await client.setClientRole("host"));
      let audioTrack =
        stateCtx.config.role === ROLES.HOST.name ||
        stateCtx.config.role === ROLES.CO_HOST.name
          ? await client.startLive(stateCtx.config.microphoneId)
          : undefined;
      console.log();
      mutationCtx.setLocalStream(audioTrack);
    } catch (error) {
      // alert('Room Joining Failed');
      console.error("Room Joining Failed", error);
    }
  };

  const handleOnClickParticipant = (uid) => {
    // alert('I clicked: ' + uid)
    MENU_TYPES.PARTICIPANT_INFO.PLACEHOLDER_TEXT = uid;
    mutationCtx.setOffCanvasMenuType(MENU_TYPES.PARTICIPANT_INFO.NAME);
    mutationCtx.toggleOffCanvasMenu(!stateCtx.offCanvasMenu);
  };

  const getSliderSettings = () => {
    // let usersCount = 0;
    // let slidesPerRow = 1;
    // if (remoteUsers) {
    //   usersCount = Object.keys(remoteUsers).length;
    //   slidesPerRow = usersCount <= 2 ? usersCount : 3;
    // }
    return {
      dots: true,
      centerMode: true,
      className: 'center',
      infinite: false,
      centerPadding: "12px",
      slidesToShow: 1,
      speed: 500,
      rows: 2,
      slidesPerRow: 3, //slidesPerRow //,
      arrows : false,
    };
  };

  const getSliderSettingsHosts = (users) => {
    // let usersCount = users.filter((uid) => ROLES.AUDIENCE.postfix !== uid.slice(uid.length - 2, uid.length));
    // let slidesPerRow = 1;

    // if (remoteUsers) {
    //   usersCount = users.filter((uid) => ROLES.AUDIENCE.postfix !== uid.slice(uid.length - 2, uid.length));
    //   slidesPerRow = usersCount <= 2 ? usersCount : 3;
    // }
    return {
      dots: true,
      className: 'center',
      centerMode: true,
      infinite: false,
      centerPadding: "50px",
      slidesToShow: 1,
      speed: 500,
      rows: 1,
      slidesPerRow: 2, //,
      arrows : false,
    };
  };
  return (
    <div>
      <div>
        <AlertBox
          makeVisible={stateCtx.alertBox}
          menuType={stateCtx.alertBoxType}
          isDismissible={true}
        />
      </div>

      <ModalBox
        makeVisible={stateCtx.modalMenu}
        menuType={stateCtx.modalMenuType}
      />

      <OffCanvasMenu
        makeVisible={stateCtx.offCanvasMenu}
        menuType={stateCtx.offCanvasMenuType}
      />
      <TopHeader
        meetingName={stateCtx.config.channelName}
        networkStatus={stateCtx.networkQuality}
      />
      <div className="container-fluid">
        <div className="row justify-content-center">
          <HeadingText
            isMain={false}
            textContent="Speakers"
            externalClasses={"m-2"}
          />
        </div>
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <Slider {...getSliderSettingsHosts()} className='w-100'>
            {(stateCtx.config.role === ROLES.HOST.name ||
              stateCtx.config.role === ROLES.CO_HOST.name) && (
              <div>
                <Participant
                  key={`participant-${stateCtx.config.uid}`}
                  participantName={stateCtx.config.uid}
                  role={stateCtx.config.role}
                  isSelf={true}
                />
              </div>
            )}

            {Object.keys(remoteUsers).map((participant_uid) => {
              let postfixRole = participant_uid.slice(
                participant_uid.length - 2,
                participant_uid.length
              );
              let _role = "";
              if (postfixRole === ROLES.HOST.postfix) {
                _role = ROLES.HOST.name;
              } else if (postfixRole === ROLES.CO_HOST.postfix) {
                _role = ROLES.CO_HOST.name;
              }
              return (
                _role !== "" && (
                  <div>
                    <Participant
                      key={`participant-${participant_uid}`}
                      participantName={participant_uid}
                      role={_role}
                      onClickHandle={handleOnClickParticipant}
                    />
                  </div>
                )
              );
            })}
          </Slider>
        </div>
      </div>
      <div className="container-fluid mt-4">
        <div className="row justify-content-center">
          <HeadingText
            isMain={false}
            textContent="Meeting Participants"
            externalClasses={"m-2"}
          />
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <Slider {...getSliderSettings()}>
            {!(
              stateCtx.config.role === ROLES.HOST.name ||
              stateCtx.config.role === ROLES.CO_HOST.name
            ) && (
              <div>
                <Participant
                  key={`participant-${stateCtx.config.uid}`}
                  participantName={stateCtx.config.uid}
                  role={stateCtx.config.role}
                  isSelf={true}
                />
              </div>
            )}
            {Object.keys(remoteUsers).map((participant_uid) => {
              let postfixRole = participant_uid.slice(
                participant_uid.length - 2,
                participant_uid.length
              );
              let _role = "";
              if (postfixRole === ROLES.AUDIENCE.postfix) {
                _role = ROLES.AUDIENCE.name;
              }
              return (
                _role !== "" && (
                  <div>
                    <Participant
                      key={`participant-${participant_uid}`}
                      participantName={participant_uid}
                      role={_role}
                      onClickHandle={handleOnClickParticipant}
                    />
                  </div>
                )
              );
            })}
          </Slider>
        </div>
      </div>
      <BottomNav />
    </div>
  );
}

export default App;
