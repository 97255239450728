import React, {
    createContext,
    useContext,
    useReducer,
    useState,
    useEffect
} from 'react'
import { reducer, defaultState } from './store'
const StateContext = createContext({})
const MutationContext = createContext({})

export const ContainerProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, defaultState)

    window.rootState = state

    const [toasts, updateToasts] = useState([])

    const methods = {
        updateConfig(params) {
            dispatch({ type: 'config', payload: { ...state.config, ...params } })
        },
        setRTCClient(clientInstance) {
            dispatch({ type: 'rtcclient', payload: clientInstance })
        },
        setRTMClient(clientInstance) {
            dispatch({ type: 'rtmclient', payload: clientInstance })
        },
        setRTMChannel(clientInstance) {
            dispatch({ type: 'rtmchannel', payload: clientInstance })
        },
        setCodec(param) {
            dispatch({ type: 'codec', payload: param })
        },
        setVideo(param) {
            dispatch({ type: 'video', payload: param })
        },
        setAudio(param) {
            dispatch({ type: 'audio', payload: param })
        },
        setScreen(param) {
            dispatch({ type: 'screen', payload: param })
        },
        setProfile(param) {
            dispatch({ type: 'profile', payload: param })
        },
        setNetworkStatus(param) {
            dispatch({ type: 'networkUpdate', payload: param })
        },
        toastSuccess(message) {
            updateToasts([
                ...toasts,
                {
                    variant: 'success',
                    message
                }
            ])
        },
        toastInfo(message) {
            updateToasts([
                ...toasts,
                {
                    variant: 'info',
                    message
                }
            ])
        },
        toastError(message) {
            updateToasts([
                ...toasts,
                {
                    variant: 'error',
                    message
                }
            ])
        },
        removeTop() {
            const items = toasts.filter((e, idx) => idx > 0)
            updateToasts([...items])
        },
        setLocalStream(param) {
            dispatch({ type: 'localStream', payload: param })
        },
        setCurrentStream(param) {
            dispatch({ type: 'currentStream', payload: param })
        },
        setDevicesList(param) {
            dispatch({ type: 'devicesList', payload: param })
        },
        clearAllStream() {
            dispatch({ type: 'clearAllStream' })
        },
        addLocal(evt) {
            const { stream } = evt
            methods.setLocalStream(stream)
            methods.setCurrentStream(stream)
        },
        addStream(evt) {
            const { stream } = evt
            dispatch({ type: 'addStream', payload: stream })
        },
        removeStream(evt) {
            const { stream } = evt
            dispatch({ type: 'removeStream', stream: stream })
        },
        removeStreamById(evt) {
            const { uid } = evt
            dispatch({ type: 'removeStream', uid: uid })
        },
        connectionStateChanged(curState, revState) {
            methods.toastInfo(`${curState}`)
        },
        toggleOffCanvasMenu(param) {
            dispatch({ type: 'offcanvas', payload: param })
        },
        setOffCanvasMenuType(params) {
            dispatch({ type: 'offcanvastype', payload: params })
        },
        toggleModalMenu(param) {
            dispatch({ type: 'modalmenu', payload: param })
        },
        setModalMenuType(params) {
            dispatch({ type: 'modalmenutype', payload: params })
        },
        toggleAlertBox(param) {
            dispatch({ type: 'alertbox', payload: param })
        },
        setAlertBoxType(params) {
            dispatch({ type: 'alertboxtype', payload: params })
        },
        leaveCall(params) {
            dispatch({ type: 'leavecall', payload: params })
        },
        leaveCallJoinBr(params) {
            dispatch({ type: 'leavecall-joinbr', payload: params })
        },
        setDominantUser(params) {
            dispatch({ type: 'dominantuser', payload: params })
        },

        // enableBeauty(enable) {
        //   dispatch({type: 'enableBeauty', enable});
        // }
    }

    useEffect(() => {
        window.sessionStorage.setItem(
            'custom_storage',
            JSON.stringify({
                uid: state.config.uid,
                host: state.config.host,
                channelName: state.config.channelName,
                token: state.config.token,
                resolution: state.config.resolution
            })
        )
    }, [state])

    return (
        <StateContext.Provider value={state}>
            <MutationContext.Provider value={methods}>
                {/* <CustomizedSnackbar toasts={toasts}/>
                {state.loading ? <Loading/> : null} */}
                {children}
            </MutationContext.Provider>
        </StateContext.Provider>
    )
}

export function useGlobalState() {
    return useContext(StateContext)
}

export function useGlobalMutation() {
    return useContext(MutationContext)
}
