import './AlertBox.css';
import { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import { useGlobalMutation, useGlobalState } from '../../utils/container';
import CONST_STRINGS from '../../constants/texts';


const { ALERT_TYPES } = CONST_STRINGS;



const AlertBox = ({ makeVisible, menuType, isDismissible }) => {
  const [show, setShow] = useState(makeVisible);
  useEffect(() => {
    setShow(makeVisible);
  }, [makeVisible]);

  const stateCtx = useGlobalState()
  const mutationCtx = useGlobalMutation()

  let alertText = '';
  let alertBody = '';
  let totalBody = '';
  switch (menuType) {
    case ALERT_TYPES.JOINING_ROOM.NAME:
      //   menuIcon = MENU_TYPES.MENU.ICON;
      alertText = ALERT_TYPES.JOINING_ROOM.PLACEHOLDER_TEXT;
      alertBody =
        <Alert show={show} variant="primary" className='alert-fixed' onClose={() => setShow(false)} dismissible={isDismissible ? true : false}>
          <div className='text-center'>{alertText}</div>
        </Alert>
      break;

    case ALERT_TYPES.JOINING_BREAKOUT_ROOM.NAME:
      //   menuIcon = MENU_TYPES.MENU.ICON;
      alertText = ALERT_TYPES.JOINING_BREAKOUT_ROOM.PLACEHOLDER_TEXT;
      alertBody =
        <Alert show={show} variant="primary" className='alert-fixed' onClose={() => setShow(false)} dismissible={isDismissible ? true : false}>
          <div className='text-center'>{alertText}</div>
        </Alert>
      break;
    case ALERT_TYPES.INVITE_SENT.NAME:
      alertText = ALERT_TYPES.INVITE_SENT.PLACEHOLDER_TEXT;
      alertBody =
        <Alert show={show} variant="primary" className='alert-fixed' onClose={() => setShow(false)} dismissible={isDismissible ? true : false}>
          <div className='text-center'>{alertText}</div>
        </Alert>
      break;
    case ALERT_TYPES.INVITE_ACCEPTED_REMOTE.NAME:
      alertText = ALERT_TYPES.INVITE_ACCEPTED_REMOTE.PLACEHOLDER_TEXT;
      alertBody =
        <Alert show={show} variant="primary" className='alert-fixed' onClose={() => setShow(false)} dismissible={isDismissible ? true : false}>
          <div className='text-center'>{alertText}</div>
        </Alert>
      break;
    case ALERT_TYPES.INVITE_DECLINED_REMOTE.NAME:
      alertText = ALERT_TYPES.INVITE_DECLINED_REMOTE.PLACEHOLDER_TEXT;
      alertBody =
        <Alert show={show} variant="primary" className='alert-fixed' onClose={() => setShow(false)} dismissible={isDismissible ? true : false}>
          <div className='text-center'>{alertText}</div>
        </Alert>
      break;

    case ALERT_TYPES.KICKED_OUT.NAME:
      alertText = ALERT_TYPES.KICKED_OUT.PLACEHOLDER_TEXT;
      alertBody =
        <Alert show={show} variant="primary" className='alert-fixed' onClose={() => setShow(false)} dismissible={isDismissible ? true : false}>
          <div className='text-center'>{alertText}</div>
        </Alert>
      break;
    case ALERT_TYPES.BE_INVITED_TO_TALK.NAME:
      alertText = ALERT_TYPES.BE_INVITED_TO_TALK.PLACEHOLDER_TEXT;
      alertBody =
        <Alert show={show} variant="primary" className='alert-fixed' onClose={() => setShow(false)} dismissible={isDismissible ? true : false}>
          <div className='text-center'>{alertText}</div>
        </Alert>
      break;
    case ALERT_TYPES.INVITATION_REQUESTED.NAME:
      let whoAsked = ALERT_TYPES.INVITATION_REQUESTED.WHICH_USER
      whoAsked = whoAsked.substring(0, whoAsked.length - 2)
      alertText = `${whoAsked} ${ALERT_TYPES.INVITATION_REQUESTED.PLACEHOLDER_TEXT}`;
      alertBody =
        <Alert show={show} variant="primary" className='alert-fixed' onClose={() => setShow(false)} dismissible={isDismissible ? true : false}>
          <div className='text-center'>{alertText}</div>
        </Alert>
      break;
    default:
      break;
  }


  return alertBody

  // return (
  //   <Alert variant='primary' dismissible={true} className='alert-fixed'>
  // {alertMessage}
  //   </Alert>
  // )
}

export default AlertBox;