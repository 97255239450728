import './styles/index-card.css';
import { React, useState, useEffect } from 'react'
import { useGlobalState, useGlobalMutation } from '../../../utils/container'
import useRouter from '../../../utils/use-router'
import { Link, useNavigate } from 'react-router-dom'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { getMeetingDetails } from '../../../utils/api/requests';


import CONST_STRINGS from '../../../constants/texts';
const { ROLES } = CONST_STRINGS;

export default function IndexCard() {

    const routerCtx = useRouter()
    const stateCtx = useGlobalState()
    const mutationCtx = useGlobalMutation()
    const navigate = useNavigate();

    let pathName = window.location.pathname;
    let showRoles = pathName === '/audience' ? false : true;
    let [isLoading, setisLoading] = useState(!showRoles ? true : false);
    let [meetingDetails, setmeetingDetails] = useState(null);

    // let meetingDetails = null;

    useEffect(() => {
        if (!meetingDetails) {
            requestMeetingDetails()
        }
        if (meetingDetails) {
            const meetingData = JSON.parse(meetingDetails)
            mutationCtx.updateConfig({
                role: ROLES.AUDIENCE.name,
                channelName: meetingData.MEETING_NAME,
                br1: meetingData.BREAKOUT_ROOM_1,
                br2: meetingData.BREAKOUT_ROOM_2,
                br3: meetingData.BREAKOUT_ROOM_3,
                br4: meetingData.BREAKOUT_ROOM_4,
            })
        }
    }, [meetingDetails])


    const statusCheck = () => {
        console.log('set int');
        if (stateCtx.config.channelName !== '' && !showRoles) {
            let x = setTimeout(() => !x && navigate(`/setting`, { replace: true }), 1500);
        }
    }
    // statusCheck()
    // setInterval(statusCheck(), 1500);


    const requestMeetingDetails = async (params) => {
        try {
            const meetingData = await getMeetingDetails()
            console.log(meetingData);
            setmeetingDetails(JSON.stringify(meetingData));
            setisLoading(!isLoading);
            // return meetingData;
        } catch (error) {
            alert('Oops! Network request failed, Try again');
        }

    }


    const handleSubmit = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        navigate(`/setting`, { replace: true })
        // if (stateCtx.config.channelName === '') {
        //     return
        // }

        // mutationCtx.startLoading()
        // navigate(`/meeting/${stateCtx.config.channelName}`, { replace: true });
        // navigate(`/setting`, { replace: true });

        // history.push(`/meeting/${stateCtx.config.channelName}`)
    }

    const handleChange = (evt) => {
        const { value, checked } = evt.target
        console.log('value', value, checked, evt, ROLES[value])
        mutationCtx.updateConfig({
            role: ROLES[value].name
        })
    }

    return (
        <>

            {isLoading && !showRoles && <div className="overlay">
                <div className="d-flex justify-content-center align-items-center">
                    <span className="sr-only">Loading...</span>
                    <div className="spinner-grow text-primary" role="status" style={{ "width": "3rem", "height": "3rem" }}>
                    </div>
                </div>
            </div>}

            {!isLoading && !showRoles && 
                <Row>
                    <Col>
                        <div className='vh-100 d-flex align-items-center'>
                            <div className='border border-2 container-fluid p-3'>
                                <h4 className='text-center'>Joining Stream Name</h4>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Control type="text" placeholder="Type channel to join"
                                        disabled
                                        value={stateCtx.config.channelName}
                                    />
                                </Form.Group>
                                <Button variant="primary"
                                    onClick={e => handleSubmit(e)}
                                >
                                    Next
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            }

            {
                showRoles &&
                <Row>
                    <Col>
                        {/* <Link to="/setting" className="setting-btn" /> */}
                        <div className="role-container">
                            {/* {`${stateCtx.config.role}`} */}
                        </div>

                        {/* <div className='vh-100 d-flex align-items-center justify-content-center'> */}
                        <div className='vh-100 d-flex align-items-center'>
                            <Form className='border border-2 container-fluid p-3'>
                                <h2 className='text-center'>Organizer Role Setup</h2>
                                <Form.Group className="mb-3 d-grid gap-2" controlId="formBasicCheckbox">
                                    <Form.Label className='fw-semibold'>Select Role</Form.Label>
                                    <Form.Check
                                        type="radio"
                                        name='role-radio'
                                        id='role-host'
                                        label='Host'
                                        value='HOST'
                                        checked={stateCtx.config.role === ROLES.HOST.name}
                                        onChange={(e) => handleChange(e)}

                                    ></Form.Check>
                                    <Form.Check
                                        type="radio"
                                        name='role-radio'
                                        id='role-cohost'
                                        label='Co-Host'
                                        value='CO_HOST'
                                        checked={stateCtx.config.role === ROLES.CO_HOST.name}
                                        onChange={(e) => handleChange(e)}
                                    >
                                    </Form.Check>
                                    {/* <Form.Check
                                    type="radio"
                                    name='role-radio'
                                    id='role-audience'
                                    label='Audience'
                                    value='AUDIENCE'
                                    checked={stateCtx.config.role === ROLES.AUDIENCE.name}
                                    onChange={(e) => handleChange(e)}
                                >
                                </Form.Check> */}

                                </Form.Group>
                                {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Please Enter a Channel Name</Form.Label>
                                <Form.Control type="text" placeholder="Type channel to join"
                                    onChange={(e) => mutationCtx.updateConfig({
                                        channelName: e.target.value
                                    })}
                                    value={stateCtx.config.channelName}
                                />
                                <span className={`text-danger d-${(stateCtx.config.channelName === '') ? 'block' : 'none'}`}>
                                    Please enter a valid channel name
                                </span>
                            </Form.Group> */}
                                <Button variant="primary"
                                    onClick={e => handleSubmit(e)}
                                // disabled={(stateCtx.config.channelName === '') ? true : false}
                                >
                                    Next
                                </Button>
                            </Form>
                        </div>
                    </Col>
                </Row>}
        </>
    )
}
