import React from 'react'
import { Routes, Route } from 'react-router-dom'
import IndexCard from './index-card'
import SettingsCard from './settings-card'


export default function CardPage() {
    return (
      <Routes>
        <Route path="/" element={<IndexCard />} />
        <Route path="/setting" element={<SettingsCard />} />
      </Routes>
        
    )
}
