import React, {useEffect} from 'react'
import {useGlobalState, useGlobalMutation} from '../../utils/container'
import IndexCard from './index/card'

import Container from 'react-bootstrap/Container';



const Index = () => {
    const stateCtx = useGlobalState()
    const mutationCtx = useGlobalMutation()

    useEffect(() => {
        if (stateCtx.loading === true) {
            mutationCtx.stopLoading()
        }
    }, [stateCtx.loading, mutationCtx])

    return (
        <Container>
            <IndexCard/>
        </Container>
    )
}

export default Index
