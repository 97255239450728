import React from 'react'
import '../../../App.css';
import { useGlobalState, useGlobalMutation } from '../../../utils/container'
import useDevices from '../../../utils/use-devices'
import { getTokens } from '../../../utils/api/requests';
import PropTypes from 'prop-types'


import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Icon from '../../../components/Icon/Icon';

import { Link, useNavigate } from 'react-router-dom'
import AlertBox from '../../../components/AlertBox/AlertBox';

import CONST_STRINGS from '../../../constants/texts';
const { ROLES, ALERT_TYPES } = CONST_STRINGS;

SettingsCard.propTypes = {
    name: PropTypes.string,
    resolution: PropTypes.string,
    cameraDevice: PropTypes.string,
    microphoneDevice: PropTypes.string,
    video: PropTypes.bool,
    audio: PropTypes.bool
}


export default function SettingsCard() {
    // const classes = useStyles()

    const stateCtx = useGlobalState()
    const mutationCtx = useGlobalMutation()

    const navigate = useNavigate();

    const [microphoneList] = useDevices()

    const handleSubmit = async (evt) => {

        if (stateCtx.config.uid === '') {
            // mutationCtx.toastError('You need enter the channel name')
            evt.preventDefault();
            evt.stopPropagation();
            return
        }
        mutationCtx.setAlertBoxType(ALERT_TYPES.JOINING_ROOM.NAME)
        mutationCtx.toggleAlertBox(!stateCtx.alertBox)
        // navigate(`/meeting/${stateCtx.config.channelName}`, { replace: true });

        const _postfix = getMyRolePostfix();
        console.log("_postfix",_postfix);
        const tokens = await getTokens({
            channelName: stateCtx.config.channelName,
            uid: `${stateCtx.config.uid}${_postfix}`
        })
        mutationCtx.updateConfig({
            rtcToken: tokens.tokenRTC, rtmToken: tokens.tokenRTM , uid: `${stateCtx.config.uid}${_postfix}`
        })

        setTimeout(() => {
            mutationCtx.toggleAlertBox(false)
            navigate(`/meeting`, { replace: true });
        }, 1500);

        // history.push(`/meeting/${stateCtx.config.channelName}`)
    }

    const getMyRolePostfix = () => {
        switch (stateCtx.config.role) {
            case "Host":
                return ROLES.HOST.postfix;
            case "Co-Host":
                return ROLES.CO_HOST.postfix;
            case "Audience":
                return ROLES.AUDIENCE.postfix;
            default:
                break;
        }
    }

    return (
        <>
        {/* {`${stateCtx.config.role} ${stateCtx.config.channelName}`} */}
            <AlertBox makeVisible={stateCtx.alertBox} menuType={stateCtx.alertBoxType}/>
            <div className='container'>
                <Row>
                    <Col>
                        <div className="role-container">
                            {/* {`${stateCtx.config.channelNacol-auto me}`} */}
                        </div>

                        <div className='vh-100 d-flex align-items-center justify-content-center'>

                            <Form className='border border-2 container-fluid p-3'>
                                <div className='maintheme-dark-color p-2' style={{ width: 'fit-content' }} >
                                    <Link to=".." className="setting-btn text-white maintheme-dark-color" >
                                        <Icon
                                            name='left-arrow' /></Link>
                                </div>
                                <h2 className='text-center'>PreJoin Settings</h2>

                                <Form.Group className="mb-3 d-grid gap-2" controlId="formBasicCheckbox">
                                    <Form.Label className='fw-semibold'>Select Mic</Form.Label>
                                    <Form.Select aria-label="Default select example"
                                        className='text-wrap'
                                        value={stateCtx.config.microphoneId}
                                        onChange={(evt) => {
                                            mutationCtx.updateConfig({
                                                microphoneId: evt.target.value
                                            })
                                        }}
                                    >
                                        {microphoneList.map((item, key) => (
                                            <option key={key} value={item.value}>{item.label}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Enter Your Name</Form.Label>
                                    <Form.Control type="text" placeholder="Your display name during meeting"
                                        onChange={(e) => mutationCtx.updateConfig({
                                            uid: e.target.value
                                        })} value={stateCtx.config.uid} />
                                    <span className={`text-danger d-${(stateCtx.config.uid === '') ? 'block' : 'none'}`}>
                                        * Name is required
                                    </span>
                                </Form.Group>
                                {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Test Speakers</Form.Label>
                    <audio></audio> 
                </Form.Group> */}
                                <Button variant="primary"
                                    onClick={e => handleSubmit(e)}
                                    disabled={(stateCtx.config.uid === '') ? true : false}>
                                    Join Stream
                                </Button>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </div>
        </>

    )
}
