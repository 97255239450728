import CONST_STRINGS from '../constants/texts';
const { ROLES, MENU_TYPES, MODAL_TYPES, ALERT_TYPES } = CONST_STRINGS;

const readDefaultState = () => {
    try {
        return JSON.parse(window.sessionStorage.getItem('custom_storage'))
    } catch (err) {
        return {}
    }
}


const defaultState = {
    // loading effect
    alertBox: false,
    alertBoxType: '',
    // media devices
    streams: [],
    localStream: null,
    currentStream: null,
    otherStreams: [],
    devicesList: [],
    // web sdk params
    config: {
        uid: '',
        role: '', //ROLES.HOST.name,
        channelName: '',
        br1: '',
        br2: '',
        br3: '',
        br4: '',
        rtcToken: '',
        rtmToken: '',
        resolution: '480p',
        // ...readDefaultState(),
        microphoneId: '',
        cameraId: ''
    },
    rtcClient: null,
    rtmClient: null,
    rtmChannel: null,
    mode: 'live',
    codec: 'vp8',
    muteVideo: false,
    muteAudio: false,
    screen: false,
    profile: false,
    networkQuality: {
        style: 'text-dark',
        placeholder: 'Connecting'
    },
    offCanvasMenu: false,
    offCanvasMenuType: MENU_TYPES.MENU.NAME,
    modalMenu: false,
    modalMenuType: MODAL_TYPES.INVITED.NAME,
    dominantUid: ''
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'config': {
            return { ...state, config: action.payload }
        }
        case 'rtcclient': {
            return { ...state, rtcClient: action.payload }
        }
        case 'rtmclient': {
            return { ...state, rtmClient: action.payload }
        }
        case 'rtmchannel': {
            return { ...state, rtmChannel: action.payload }
        }
        case 'codec': {
            return { ...state, codec: action.payload }
        }
        case 'video': {
            return { ...state, muteVideo: action.payload }
        }
        case 'audio': {
            return { ...state, muteAudio: action.payload }
        }
        case 'screen': {
            return { ...state, screen: action.payload }
        }
        case 'devicesList': {
            return { ...state, devicesList: action.payload }
        }
        case 'localStream': {
            return { ...state, localStream: action.payload }
        }
        case 'profile': {
            return { ...state, profile: action.payload }
        }
        case 'networkUpdate': {
            return { ...state, networkQuality: action.payload }
        }
        case 'currentStream': {
            const { streams } = state
            const newCurrentStream = action.payload
            const otherStreams = streams.filter(
                (it) => it.getId() !== newCurrentStream.getId()
            )
            return { ...state, currentStream: newCurrentStream, otherStreams }
        }
        case 'addStream': {
            const { streams, currentStream } = state
            const newStream = action.payload
            let newCurrentStream = currentStream
            if (!newCurrentStream) {
                newCurrentStream = newStream
            }
            if (streams.length === 4) return { ...state }
            const newStreams = [...streams, newStream]
            const otherStreams = newStreams.filter(
                (it) => it.getId() !== newCurrentStream.getId()
            )
            return {
                ...state,
                streams: newStreams,
                currentStream: newCurrentStream,
                otherStreams
            }
        }
        case 'removeStream': {
            const { streams, currentStream } = state
            const { stream, uid } = action
            const targetId = stream ? stream.getId() : uid
            let newCurrentStream = currentStream
            const newStreams = streams.filter(
                (stream) => stream.getId() !== targetId
            )
            if (currentStream && (targetId === currentStream.getId())) {
                if (newStreams.length === 0) {
                    newCurrentStream = null
                } else {
                    newCurrentStream = newStreams[0]
                }
            }
            const otherStreams = newCurrentStream
                ? newStreams.filter((it) => it.getId() !== newCurrentStream.getId())
                : []
            return {
                ...state,
                streams: newStreams,
                currentStream: newCurrentStream,
                otherStreams
            }
        }
        case 'clearAllStream': {
            // const {streams, localStream, currentStream, beauty} = state;
            const { localStream } = state
            // streams.forEach((stream) => {
            //     if (stream.isPlaying()) {
            //         stream.stop()
            //     }
            //     // stream.close();
            // })

            if (localStream) {
                if (localStream.isPlaying) {
                    localStream.stop()
                }
                localStream.close()
            }
            // if (currentStream) {
            //     currentStream.isPlaying() && currentStream.stop()
            //     currentStream.close()
            // }
            return { ...state, currentStream: null, localStream: null, streams: [] }
        }
        case 'offcanvas': {
            return { ...state, offCanvasMenu: action.payload }
        }
        case 'offcanvastype': {
            return { ...state, offCanvasMenuType: action.payload }
        }
        case 'modalmenu': {
            return { ...state, modalMenu: action.payload }
        }
        case 'modalmenutype': {
            return { ...state, modalMenuType: action.payload }
        }
        case 'alertbox': {
            return { ...state, alertBox: action.payload }
        }
        case 'alertboxtype': {
            return { ...state, alertBoxType: action.payload }
        }
        case 'dominantuser': {
            return { ...state, dominantUid: action.payload }
        }
        case 'leavecall': {
            let { rtcClient, rtmClient, rtmChannel } = state;
            (async (params) => {
                await rtcClient.leave();
                await rtmChannel.leave();
                await rtmClient.logout();

            })()
            // reset states to default
            return { ...defaultState }
        }
        case 'leavecall-joinbr': {
            let { rtcClient, rtmClient, rtmChannel } = state;
            (async (params) => {
                await rtcClient.leave();
                await rtmChannel.leave();
                await rtmClient.logout();

            })()
            // reset states to default
            const tempState = { ...defaultState };
            tempState.config.uid = state.config.uid;
            tempState.config.role = state.config.role
            tempState.config.channelName = state.config.channelName
            tempState.config.rtcToken = state.config.rtcToken
            tempState.config.rtmToken = state.config.rtmToken
            tempState.config.microphoneId = state.config.microphoneId
            tempState.config.br1 = state.config.br1
            tempState.config.br2 = state.config.br2
            tempState.config.br3 = state.config.br3
            tempState.config.br4 = state.config.br4
            return { ...tempState }
        }
        default:
            throw new Error('mutation type not defined')
    }
}

export { reducer, defaultState }
