import instance from 'axios';

const ENDPOINTS = {
    GET_TOKEN: '/generateToken', GET_INFO: '/getMeetingInfo'
}
const BASE_URL = 'https://nb-agora-nodebackend.herokuapp.com'

export const getTokens = async function({channelName, uid}) {
    const payload = {
        channelName,
        uid,
        'role': ''
    }
    try {
        let response = await instance.post(BASE_URL + ENDPOINTS.GET_TOKEN, payload);        
        return (await response.data)
    } catch (error) {
        throw error;
    }
}

export const getMeetingDetails = async function() {
    
    try {
        let response = await instance.get(BASE_URL + ENDPOINTS.GET_INFO);        
        return (await response.data)
    } catch (error) {
        throw error;
    }
}
