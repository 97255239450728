import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { ContainerProvider } from './utils/container'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render
  (
    <ContainerProvider>
      <App />
    </ContainerProvider>
  );

