import './BottomNav.css';
import Nav from 'react-bootstrap/Nav';
import BodyText from '../../components/BodyText/BodyText';
import Icon from '../../components/Icon/Icon';
import CONST_STRINGS from '../../constants/texts';
import { useGlobalMutation, useGlobalState } from '../../utils/container';

const { ICONS, MENU_TYPES, MODAL_TYPES, ROLES, ALERT_TYPES } = CONST_STRINGS;

const BottomNav = () => {
    const stateCtx = useGlobalState();
    const mutationCtx = useGlobalMutation();
    const handleMute = async () => {
        // alert('bulaya')
        if (!stateCtx.localStream) return;
        /**
         * After calling setMuted to mute an audio or video track, the SDK stops sending the audio or video stream. Users whose tracks are muted are not counted as users sending streams.
         * Calling setEnabled to disable a track, the SDK stops audio or video capture
         */
        await stateCtx.localStream.setMuted(!stateCtx.muteAudio);
        mutationCtx.setAudio(!stateCtx.muteAudio);

    }

    const handleCallEnd = (params) => {
        mutationCtx.setModalMenuType(MODAL_TYPES.SURE_ENDCALL.NAME);
        mutationCtx.toggleModalMenu(!stateCtx.modalMenu);
    }

    const handleOffCanvasToggle = (clicked) => {
        switch (clicked) {
            case ICONS.MENU_BARS.ICON_NAME:
                mutationCtx.setOffCanvasMenuType(MENU_TYPES.MENU.NAME);
                mutationCtx.toggleOffCanvasMenu(!stateCtx.offCanvasMenu);
                break;
            case ICONS.BREAKOUT_ROOM.ICON_NAME:
                mutationCtx.setOffCanvasMenuType(MENU_TYPES.BREAK_OUT_ROOM.NAME);
                mutationCtx.toggleOffCanvasMenu(!stateCtx.offCanvasMenu);
                break;
            default:
                break;
        }
    }

    const handleBeInvitedToSpeak = (uid) => {
        stateCtx.rtmClient.sendChannelMessage({
            type: 'control',
            task: 'requestinvite'
        }, () => {
            // sent callback
            // alert('just')

            mutationCtx.setAlertBoxType(ALERT_TYPES.BE_INVITED_TO_TALK.NAME);
            mutationCtx.toggleAlertBox(!stateCtx.alertBox);
            setTimeout(() => {
                mutationCtx.toggleAlertBox(false);
            }, 1200);
        })
    }

    return (
        <Nav className="justify-content-center bottom-nav fixed-bottom" activeKey="/home">
            <Nav.Item className='d-flex flex-column align-self-center p-3 bg-danger border border-danger rounded-circle' onClick={handleCallEnd}>
                <Icon name={ICONS.PHONE.ICON_NAME} />
                {/* <BodyText isRegular={true} textContent="" /> */}
            </Nav.Item>

            {/* 
            Chat feature yet to be implemented in both hosts and audiecnce

            <Nav.Item className='d-flex flex-column align-self-center p-3'>
                <Icon name={ICONS.CHAT.ICON_NAME} />
                <BodyText isRegular={true} textContent={ICONS.CHAT.PLACEHOLDER_TEXT} isWhite={true} />
            </Nav.Item>
         */}

            {   // IF Audience
                (stateCtx.config.role === ROLES.AUDIENCE.name) &&
                <Nav.Item className='d-flex flex-column align-self-center p-3' onClick={() => { handleBeInvitedToSpeak() }}>
                    <Icon name={ICONS.BE_INVITED.ICON_NAME} />
                    <BodyText isRegular={true} textContent={ICONS.BE_INVITED.PLACEHOLDER_TEXT} isWhite={true} />
                </Nav.Item>
            }

            <Nav.Item className='d-flex flex-column align-self-center p-3' onClick={handleMute}>
                <Icon name={!stateCtx.muteAudio ? ICONS.MIC.ICON_NAME : ICONS.MIC_MUTE.ICON_NAME} />
                <BodyText isRegular={true} textContent={ICONS.MIC.PLACEHOLDER_TEXT} isWhite={true} />
            </Nav.Item>
            <Nav.Item className='d-flex flex-column align-self-center p-3' onClick={() => handleOffCanvasToggle(ICONS.BREAKOUT_ROOM.ICON_NAME)}>
                <Icon name={ICONS.BREAKOUT_ROOM.ICON_NAME} />
                <BodyText isRegular={true} textContent={ICONS.BREAKOUT_ROOM.PLACEHOLDER_TEXT} isWhite={true} />
            </Nav.Item>
            <Nav.Item className='d-flex flex-column align-self-center p-3' onClick={() => { handleOffCanvasToggle(ICONS.MENU_BARS.ICON_NAME) }}>
                <Icon name={ICONS.MENU_BARS.ICON_NAME} />
                {/* <BodyText isRegular={true} textContent="" /> */}
            </Nav.Item>
        </Nav>
    )
}

export default BottomNav;