import React, {useContext} from 'react'
import {HashRouter as Router, Route, Routes} from 'react-router-dom'

export const RouterContext = React.createContext({})

export const BrowserRouterHook = ({children}) => (
    <Router>
        <Routes>
            <Route>
                {(routeProps) => (
                    <RouterContext.Provider value={routeProps}>
                        {children}
                    </RouterContext.Provider>
                )}
            </Route>
        </Routes>
    </Router>
)

export default function useRouter() {
    return useContext(RouterContext)
}
