
const TEXTS = {
    MEETING_ROOM_NAME: "Meeting Room Name",
    IN_MEETING: "In Meeting: 01:23:20"
}

const ROLES = {
    HOST: { name: "Host", postfix: '_h' },
    CO_HOST: { name: "Co-Host", postfix: '_c' },
    AUDIENCE: { name: "Audience", postfix: '_a' }
}

const ICONS = {
    MIC: {
        ICON_NAME: 'mic',
        PLACEHOLDER_TEXT: "Mic"
    },
    MIC_MUTE: {
        ICON_NAME: 'mic-muted',
        PLACEHOLDER_TEXT: "Mic"
    },
    CHAT: {
        ICON_NAME: 'chat',
        PLACEHOLDER_TEXT: "Chat"
    },
    RECORDING: {
        ICON_NAME: 'record',
        PLACEHOLDER_TEXT: "Record Meeting"
    },
    MUSIC_LIBRARY: {
        ICON_NAME: 'library',
        PLACEHOLDER_TEXT: "Media Library"
    },
    BREAKOUT_ROOM: {
        ICON_NAME: 'breakout-room',
        PLACEHOLDER_TEXT: "Breakout Room"
    },
    SETTINGS: {
        ICON_NAME: 'setting',
        PLACEHOLDER_TEXT: "Settings"
    },
    PHONE: {
        ICON_NAME: 'end-call',
        PLACEHOLDER_TEXT: ""
    },
    MENU_BARS: {
        ICON_NAME: 'menu',
        PLACEHOLDER_TEXT: ""
    },
    SIGNAL: {
        ICON_NAME: 'signal',
        PLACEHOLDER_TEXT: {
            GOOD: 'Good',
            NORMAL: 'Normal',
            BAD: 'Bad'
        }
    },
    NETWORK: {
        ICON_NAME: 'network',
        PLACEHOLDER_TEXT: {
            GOOD: 'Good',
            NORMAL: 'Normal',
            BAD: 'Bad'
        }

    },
    INVITE_STAGE: {
        ICON_NAME: 'invite',
        PLACEHOLDER_TEXT: "Invite to Stage"
    },
    EXIT_ROOM: {
        ICON_NAME: 'kickout',
        PLACEHOLDER_TEXT: "Kickout from meeting"
    },
    BE_INVITED: {
        ICON_NAME: 'be-invited',
        PLACEHOLDER_TEXT: "Become Speaker"
    },




}

const MENU_TYPES = {
    MENU: { NAME: 'MENU', ICON: ICONS.MENU_BARS.ICON_NAME, PLACEHOLDER_TEXT: 'Menu' },
    PARTICIPANT_INFO: { NAME: 'PARTICIPANT_INFO', ICON: '', PLACEHOLDER_TEXT: '' },
    BREAK_OUT_ROOM: { NAME: 'BREAK_OUT_ROOM', ICON: ICONS.BREAKOUT_ROOM.ICON_NAME, PLACEHOLDER_TEXT: 'Breakout Rooms' },
}

const MODAL_TYPES = {
    INVITED: { NAME: 'INVITED', PLACEHOLDER_TEXT: 'Invited to Speak' },
    SURE_ENDCALL: { NAME: 'SURE_ENDCALL', ICON: '', PLACEHOLDER_TEXT: 'Are you sure to leave?' },
    SURE_JOIN_BREAKOUT: {
        NAME: 'SURE_JOIN_BREAKOUT',
        ICON: '',
        PLACEHOLDER_TEXT: 'Leaving this room to join breakout room, you sure?',
        WHICH_BREAKOUT: ''
    },
}

const ALERT_TYPES = {
    INVITE_SENT: { NAME: 'INVITE_SENT', PLACEHOLDER_TEXT: 'Invitation sent!' },
    INVITE_ACCEPTED_REMOTE: { NAME: 'INVITE_ACCEPTED_REMOTE', ICON: '', PLACEHOLDER_TEXT: 'accepted your invite' },
    INVITE_DECLINED_REMOTE: { NAME: 'INVITE_DECLINED_REMOTE', ICON: '', PLACEHOLDER_TEXT: 'refused to speak on stage' },

    BE_INVITED_TO_TALK: { NAME: 'BE_INVITED_TO_TALK', PLACEHOLDER_TEXT: 'Request to Participate sent!' },
    INVITATION_REQUESTED: { NAME: 'INVITATION_REQUESTED', PLACEHOLDER_TEXT: 'requested to speak', WHICH_USER: '' },


    JOINING_ROOM: { NAME: 'JOINING_ROOM', PLACEHOLDER_TEXT: 'Joining Stream Now, Please wait!' },
    KICKED_OUT: { NAME: 'KICKED_OUT', PLACEHOLDER_TEXT: 'User has been kicked out' },
    JOINING_BREAKOUT_ROOM: { NAME: 'JOINING_BREAKOUT_ROOM', PLACEHOLDER_TEXT: 'Joining Breakout Room Now, Please wait!' },


}





let CONST_STRINGS = { TEXTS, ICONS, ROLES, MENU_TYPES, MODAL_TYPES, ALERT_TYPES }
export default CONST_STRINGS;
