import React from 'react'
import './App.css'
import {Route, Routes, BrowserRouter} from 'react-router-dom'
import Index from './pages/prejoin/index'
import SettingsCard from './pages/prejoin/index/settings-card'

import Meeting from './pages/meeting/MeetingRoom'
import Breakout from './pages/breakout/BreakoutRoom'

import {BrowserRouterHook} from './utils/use-router'

function App() {
    return (
        // <BrowserRouterHook>
        //     <Routes>
        //         {/* <Route exact path="/meeting/:name" component={Meeting}></Route> */}
        //         <Route path="/" component={Meeting}></Route>
        //     </Routes>
        // </BrowserRouterHook>

    <BrowserRouter>
      <Routes>
      <Route path="/" element={<div>Not Found!</div>} />
        <Route path="/organizers" element={<Index />} />
        <Route path="/audience" element={<Index />} />
        <Route path="/setting" element={<SettingsCard />} />
        <Route path="/meeting" element={<Meeting />} />
        <Route path="/breakout" element={<Breakout />} />

      </Routes>
    </BrowserRouter>
    )
}

export default App
