import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDoorOpen,
  faMicrophone,
  faComments,
  faBars,
  faPhone,
  faGear,
  faMusic,
  faRecordVinyl,
  faSignal,
  faArrowLeft,
  faMicrophoneSlash,
  faTowerBroadcast,
  faPeopleArrowsLeftRight,
  faRightFromBracket,
  faPersonChalkboard
} from '@fortawesome/free-solid-svg-icons'

import './Icon.css'
const Icon = ({ name, externalClasses }) => {
  let selectedIcon = null
  let styleClass = "fa-icon-style";
  styleClass = externalClasses ? styleClass + ` ${externalClasses}` : styleClass;
  switch (name) {
    case 'mic':
      selectedIcon = faMicrophone;
      break;
    case 'mic-muted':
      selectedIcon = faMicrophoneSlash;
      break;
    case 'chat':
      selectedIcon = faComments;
      break;
    case 'record':
      selectedIcon = faRecordVinyl;
      break;
    case 'library':
      selectedIcon = faMusic;
      break;
    case 'breakout-room':
      selectedIcon = faDoorOpen;
      break;
    case 'setting':
      selectedIcon = faGear;
      break;
    case 'end-call':
      selectedIcon = faPhone;
      break;
    case 'menu':
      selectedIcon = faBars;
      break;
    case 'signal':
      selectedIcon = faSignal;
      break;
    case 'network':
      selectedIcon = faTowerBroadcast;
      break;
    case 'left-arrow':
      selectedIcon = faArrowLeft;
      break;
    case 'invite':
      selectedIcon = faPeopleArrowsLeftRight;
      break;
    case 'kickout':
      selectedIcon = faRightFromBracket;
      break;
      case 'be-invited':
        selectedIcon = faPersonChalkboard;
        break;
    default:
      break;
  }
  return <FontAwesomeIcon icon={selectedIcon} className={styleClass} />


}

export default Icon