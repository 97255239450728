import './Participant.css'
import Image from 'react-bootstrap/Image';
import avatar from '../../assets/avatar.jpg';
import 'bootstrap/dist/css/bootstrap.css';
import '../HeadingText/HeadingText.css';
import { useGlobalState } from '../../utils/container';
const Participant = ({ participantName, role, isSelf, onClickHandle }) => {
    const stateCtx = useGlobalState()
    let parsedParticipantName = participantName.substring(0, participantName.length - 2)
      
    let displayName = (isSelf) ? parsedParticipantName + " (Me)" : parsedParticipantName;
    let amIDominant = stateCtx.dominantUid === participantName? true : false; 
    return (
        <div className='col-auto justify-content-center' onClick={() => onClickHandle(participantName)}>
            <Image src={`https://ui-avatars.com/api/?name=${parsedParticipantName}&size=120`} className={ amIDominant ? `p-1 mb-1 mx-auto shadow-lg border-success rounded` : 'mx-auto border-0 rounded'} width="100px" height="100px" />
            {(role === "Host" || role === "Co-Host") && <p className='headingtext-main text-center m-0 p-0'>{role}</p>}
            <p className='bodytext-participant text-center text-break m-0 p-0'>{displayName}</p>
        </div>
    )
}

export default Participant;

