import './TopHeader.css';
import Nav from 'react-bootstrap/Nav';
import HeadingText from '../../components/HeadingText/HeadingText';
import BodyText from '../../components/BodyText/BodyText';
import Icon from '../../components/Icon/Icon';
import logo from "../../assets/nbIcon.png";
import Timer from "react-compound-timerv2/build";
import CONST_STRINGS from '../../constants/texts';

const { TEXTS, ICONS } = CONST_STRINGS;



const TopHeader = ({ meetingName , networkStatus}) => {
    return (
        // 
        <Nav className="justify-content-center top-header" activeKey="/home">
            <Nav.Item className='d-flex flex-column align-self-center me-3'>
                <img src={logo} width="40px" height="40px" alt="Nature's Boardroom Logo"></img>
            </Nav.Item>
            <Nav.Item className=''>
                <div className='d-flex flex-column align-items-center pe-4 ps-4'>
                    <HeadingText isMain={true} textContent={meetingName || TEXTS.MEETING_ROOM_NAME} />
                    <BodyText isRegular={true} textContent={
                        <Timer initialTime={0}>
                            <Timer.Hours formatValue={value => value < 10 ? `0${value}:` : `${value}:`} />
                            <Timer.Minutes formatValue={value => value < 10 ? `0${value}:` : `${value}:`} />
                            <Timer.Seconds formatValue={value => value < 10 ? `0${value}` : value} />

                        </Timer>
                    } isWhite={true} />
                </div>
            </Nav.Item>
            <Nav.Item className='d-flex flex-column align-self-center ms-3 '>
                <Icon name="signal" externalClasses={`${networkStatus.style} bg-light border border-success p-1 rounded`} />
                <BodyText isRegular={true} textContent={networkStatus.placeholder} isWhite={true} />
            </Nav.Item>
        </Nav>
    )
}

export default TopHeader;