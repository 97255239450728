import { useState, useEffect } from 'react';
import './OffCanvasMenu.css';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ListGroup from 'react-bootstrap/ListGroup';
import Image from 'react-bootstrap/Image';


import Icon from '../Icon/Icon';
import { useGlobalMutation, useGlobalState } from '../../utils/container';

import CONST_STRINGS from '../../constants/texts';
const { MENU_TYPES, ICONS, ROLES, ALERT_TYPES,MODAL_TYPES } = CONST_STRINGS;


const OffCanvasMenu = ({ menuDetails, makeVisible, menuType }) => {
  const [show, setShow] = useState(makeVisible);
  useEffect(() => {
    setShow(makeVisible);
  }, [makeVisible]);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const stateCtx = useGlobalState()
  const mutationCtx = useGlobalMutation()

  const handleOffCanvasClose = (clicked) => {
    console.log('handleOffCanvasClose');
    setShow(false);
    mutationCtx.toggleOffCanvasMenu(!stateCtx.offCanvasMenu)
  }

  const handleInviteSendClick = (uid) => {
    // console.log('RTMHandler', RTMHandler);
    stateCtx.rtmClient.sendPeerMessage({
      type: 'control',
      task: 'invited'
    }, uid, () => {
      // sent callback
      mutationCtx.setAlertBoxType(ALERT_TYPES.INVITE_SENT.NAME);
      mutationCtx.toggleAlertBox(!stateCtx.alertBox);
      setTimeout(() => {
        mutationCtx.toggleAlertBox(false);
      }, 1200);
    })
    handleOffCanvasClose();
  }

  const handleKickoutClick = (uid) => {
    // console.log('RTMHandler', RTMHandler);
    stateCtx.rtmClient.sendPeerMessage({
      type: 'control',
      task: 'kicked'
    }, uid, () => {
      // sent callback
      mutationCtx.setAlertBoxType(ALERT_TYPES.KICKED_OUT.NAME);
      mutationCtx.toggleAlertBox(!stateCtx.alertBox);
      setTimeout(() => {
        mutationCtx.toggleAlertBox(false);
      }, 1200);
    })
    handleOffCanvasClose();
  }
  const handleBreakoutRoomItem = (ChannelName) => {
    // alert(ChannelName)
    MODAL_TYPES.SURE_JOIN_BREAKOUT.WHICH_BREAKOUT = ChannelName
    mutationCtx.setModalMenuType(MODAL_TYPES.SURE_JOIN_BREAKOUT.NAME);
    mutationCtx.toggleModalMenu(!stateCtx.modalMenu);
  }


  const handleCallEnd = (params) => {
 
}
  let menuIcon = '';
  let menuText = '';
  let menuBody = '';
  let totalBody = '';
  switch (menuType) {
    case MENU_TYPES.MENU.NAME:
      menuIcon = MENU_TYPES.MENU.ICON;
      menuText = MENU_TYPES.MENU.PLACEHOLDER_TEXT
      menuBody =

        <ListGroup>
          <ListGroup.Item>
            <Icon externalClasses='fa-icon-style-dark' name={ICONS.RECORDING.ICON_NAME} /><span className=''>{` ${ICONS.RECORDING.PLACEHOLDER_TEXT}`}</span>
          </ListGroup.Item>
          <ListGroup.Item>
            <Icon externalClasses='fa-icon-style-dark' name={ICONS.MUSIC_LIBRARY.ICON_NAME} /><span className=''>{` ${ICONS.MUSIC_LIBRARY.PLACEHOLDER_TEXT}`}</span>
          </ListGroup.Item>
          <ListGroup.Item>
            <Icon externalClasses='fa-icon-style-dark' name={ICONS.SETTINGS.ICON_NAME} /><span className=''>{` ${ICONS.SETTINGS.PLACEHOLDER_TEXT}`}</span>
          </ListGroup.Item>
        </ListGroup>
      totalBody = <>
        <Offcanvas show={show} placement="bottom" onHide={() => handleOffCanvasClose()}>
          <Offcanvas.Header className='off-canvas-menu-header' closeButton closeVariant="white">
            <Offcanvas.Title>
              <Icon name={menuIcon} /><span className='off-canvas-menu-header-title'>{` ${menuText}`}</span>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {menuBody}
          </Offcanvas.Body>
        </Offcanvas>
      </>
      break;

    case MENU_TYPES.PARTICIPANT_INFO.NAME:
      menuIcon = MENU_TYPES.PARTICIPANT_INFO.ICON;
      menuText = MENU_TYPES.PARTICIPANT_INFO.PLACEHOLDER_TEXT
      let actualName = menuText.toString().slice(0, menuText.length - 2);
      let postfixRole = menuText.toString().slice(menuText.length - 2, menuText.length);
      let role = '';
      if (postfixRole === ROLES.HOST.postfix) {
        role = ROLES.HOST.name;
      } else if (postfixRole === ROLES.CO_HOST.postfix) {
        role = ROLES.CO_HOST.name
      } else if (postfixRole === ROLES.AUDIENCE.postfix) {
        role = ROLES.AUDIENCE.name
      }
      menuBody =
        <>
          <p className=""><strong>Joined As: </strong> {role} </p>
          <p className=""><strong>Name: </strong> {actualName} </p>
          {
            stateCtx.config.role !== ROLES.AUDIENCE.name ? <><ListGroup>
              <ListGroup.Item onClick={() => { handleInviteSendClick(menuText) }}>
                <Icon externalClasses='fa-icon-style-dark' name={ICONS.INVITE_STAGE.ICON_NAME} /><span className=''>{` ${ICONS.INVITE_STAGE.PLACEHOLDER_TEXT}`}</span>
              </ListGroup.Item>
              <ListGroup.Item onClick={() => { handleKickoutClick(menuText) }}>
                <Icon externalClasses='fa-icon-style-dark' name={ICONS.EXIT_ROOM.ICON_NAME} /><span className=''>{` ${ICONS.EXIT_ROOM.PLACEHOLDER_TEXT}`}</span>
              </ListGroup.Item>
            </ListGroup>
              <p className="font-weight-bold"></p></>

              : ""
          }
        </>
      totalBody =
        <Offcanvas show={show} placement="bottom" onHide={() => handleOffCanvasClose()}>
          <Offcanvas.Header className='off-canvas-menu-header' closeButton closeVariant="white">
            <Offcanvas.Title>
              <Image src={`https://ui-avatars.com/api/?name=${menuText}&size=40`} className=' mx-auto border-0 rounded-circle' width="40px" height="40px" />
              <span className='off-canvas-menu-header-title'>{` ${actualName}`}</span>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {menuBody}
          </Offcanvas.Body>
        </Offcanvas>

      break;

    case MENU_TYPES.BREAK_OUT_ROOM.NAME:
      menuIcon = MENU_TYPES.BREAK_OUT_ROOM.ICON;
      menuText = MENU_TYPES.BREAK_OUT_ROOM.PLACEHOLDER_TEXT
      menuBody =

        <ListGroup>

          <ListGroup.Item onClick={() => handleBreakoutRoomItem(stateCtx.config.br1)}>
            <span className=''>{` ${stateCtx.config.br1}`}</span>
          </ListGroup.Item>
          <ListGroup.Item onClick={() => handleBreakoutRoomItem(stateCtx.config.br2)}>
            <span className=''>{` ${stateCtx.config.br2}`}</span>
          </ListGroup.Item>
          <ListGroup.Item onClick={() => handleBreakoutRoomItem(stateCtx.config.br3)}>
            <span className=''>{` ${stateCtx.config.br3}`}</span>
          </ListGroup.Item>
          <ListGroup.Item onClick={() => handleBreakoutRoomItem(stateCtx.config.br4)}>
            <span className=''>{` ${stateCtx.config.br4}`}</span>
          </ListGroup.Item>

        </ListGroup>
      totalBody = <>
        <Offcanvas show={show} placement="bottom" onHide={() => handleOffCanvasClose()}>
          <Offcanvas.Header className='off-canvas-menu-header' closeButton closeVariant="white">
            <Offcanvas.Title>
              <Icon name={menuIcon} /><span className='off-canvas-menu-header-title'>{` ${menuText}`}</span>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {menuBody}
          </Offcanvas.Body>
        </Offcanvas>
      </>

      break;
    default:
      break;
  }
  return (
    totalBody
  );
}
export default OffCanvasMenu;