import AgoraRTM from 'agora-rtm-sdk'
import { useGlobalMutation, useGlobalState } from './container';
import REACT_APP_AGORA_APP_ID from '../constants/env';


export function RTMWrapper() {
    const stateCtx = useGlobalState()
    const mutationCtx = useGlobalMutation()

    // Params for login
    let options = {
        uid: stateCtx.config.uid,
        token: stateCtx.config.rtmToken
    }
    let client = null;
    let channel = null;

    const createRTMClient = () => {
        // Your app ID
        const appID = REACT_APP_AGORA_APP_ID
        // Initialize client
        client = AgoraRTM.createInstance(appID)
        // Client Event listeners
        // Display messages from peer

    }

    const createRTMChannel = (params) => {

        // create channel
        console.log('creating RTM channel name: ', stateCtx.config.channelName);
        channel = client.createChannel(stateCtx.config.channelName)
        return channel;


    }

    const onEvents = (eventNameandHandlerObject) => {

        client.on('MessageFromPeer', eventNameandHandlerObject['MessageFromPeer']);
        // Display connection state changes
        client.on('ConnectionStateChanged', eventNameandHandlerObject['ConnectionStateChanged']);

        channel.on('ChannelMessage', eventNameandHandlerObject['ChannelMessage']);
        // Display channel member stats
        channel.on('MemberJoined', eventNameandHandlerObject['MemberJoined']);
        // Display channel member stats
        channel.on('MemberLeft', eventNameandHandlerObject['MemberLeft']);

    }

    const initRTM = async (joinSuccess) => {
        createRTMClient();
        let _channel = createRTMChannel();
        mutationCtx.setRTMChannel(_channel);
        console.log('Joining RTM Channel',{uid: stateCtx.config.uid, token: stateCtx.config.rtmToken});
        await client.login({ uid: stateCtx.config.uid, token: stateCtx.config.rtmToken })
        await channel.join().then(async () => {
            console.log("You have successfully joined channel " + channel.channelId)
            let alreadyPresentMembers = await channel.getMembers();
            console.log(alreadyPresentMembers);
            joinSuccess(alreadyPresentMembers);
        })
    }

    const sendPeerMessage = async (payload, peerId, sentCb, receivedCb) => {
        let message = null;
        console.log('payload', payload);
        switch (payload.type) {
            case 'control':
                message = { type: 'action', action: payload.task }
                break;
            case 'chat':
                break;
            default:
                break;
        }

        let peerMessage = { text: JSON.stringify(message) }
        // console.log('peerMessage', peerMessage);

        await client.sendMessageToPeer(
            peerMessage,
            peerId,
        ).then(sendResult => {
            if (sendResult.hasPeerReceived) {
                console.log('sendResult.hasPeerReceived');
                sentCb();
                // document.getElementById("log").appendChild(document.createElement('div')).append("Message has been received by: " + peerId + " Message: " + peerMessage)
            } else {
                // document.getElementById("log").appendChild(document.createElement('div')).append("Message sent to: " + peerId + " Message: " + peerMessage)
            }
        })
    }

    const sendChannelMessage = async (payload, sentCb) => {

        let message = null;
        console.log('payload', payload);
        switch (payload.type) {
            case 'control':
                message = { type: 'action', action: payload.task }
                break;
            case 'chat':    // group chat
                break;
            default:
                break;
        }

        let channelMessage = { text: JSON.stringify(message) }

        if (channel != null) {
            await channel.sendMessage(channelMessage).then(() => {
                sentCb()
            }

            )
        }
    }

    const logout = async () => {
        await client.logout()
    }

    const leave = async (channel) => {
        if (channel != null) {
            await channel.leave()
        }
        else {
            console.log("Channel is empty")
        }
    }

    const getMembers = async (params) => {
        let alreadyPresentMembers = await channel.getMembers();
        console.log(alreadyPresentMembers);
        return alreadyPresentMembers;
    }

    return { initRTM, sendPeerMessage, sendChannelMessage, logout, leave, onEvents, getMembers }
}

